<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>MY {{ this.messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>My {{ this.messagetype.title_jp }}</h2>
      <div class="mascot_wrap" style="display: none">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
      <div
        class="user_img"
        :style="{ backgroundImage: 'url(' + profileImg + ')' }"
        v-if="profileImg"
      ></div>
      <div
        class="user_img"
        :style="{
          backgroundImage:
            'url(' +
            require('@/assets/front_component/images/test/test01.png') +
            ')',
        }"
        v-else
      ></div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <div>
                <a style="cursor: pointer" @click="gotoBack()"
                  >My {{ getHistoryUrl() }}一覧</a
                >
              </div>
            </li>
            <li
              v-if="
                showmessage.type == 'challenge' || showmessage.type == 'sdgs'
              "
            >
              <span>{{ showYear(showmessage.option) }}</span>
            </li>
            <li v-else>
              <span>{{ showmessage.title }} </span>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div class="post_header">
              <div class="post_header_icon">
                <img
                  src="@/assets/front_component/images/assets/tab01_out.png"
                  alt="すべて表示"
                />
              </div>
              <div class="post_header_day">
                <span class="post_header_day_time"
                  ><img
                    src="@/assets/front_component/images/assets/board01.png"
                    alt="時間"
                  /><time
                    class="en"
                    :datetime="formatDate_time(showmessage.published_at)"
                    >{{ formatDate(showmessage.published_at) }}
                  </time></span
                >

                <span v-if="showmessage.from_user" class="post_header_day_name"
                  >{{ actionUserLabel }}：{{ showmessage.from_user.last_name }}
                  {{ showmessage.from_user.first_name }}</span
                >

                <template
                  v-if="
                    messagecolumn['thanks_type'] && message_type === 'thanks'
                  "
                >
                  <p class="post_header_day_name">
                    タイプ：<span
                      v-for="thanks_type in this.showmessage.thanks_type"
                      :key="thanks_type.id"
                      class="thanks_type"
                      >{{ thanks_type.value }}</span
                    >
                  </p>
                </template>
              </div>
            </div>
            <article class="post_area">
              <h1 v-if="message_type == 'thanks'">
                <span
                  v-for="userlist in this.showmessage.target_user"
                  :key="userlist.id"
                  >{{ userlist.last_name }}さん</span
                >へのサンクスカード
              </h1>
              <h1
                v-else-if="
                  showmessage.type == 'challenge' || showmessage.type == 'sdgs'
                "
              >
                {{ showYear(showmessage.option) }}
              </h1>
              <h1 v-else>{{ showmessage.title }}</h1>

              <div class="submit_wrap">
                <!-- style="display:none" -->
                <Form
                  id="aprroval_modal"
                  class="click_popup dsn2"
                  v-if="
                    messagecolumn['status_list'] == '承認' &&
                    showmessage.from_id == this.$User.id &&
                    showmessage.status != '公開'
                  "
                  :style="display"
                  enctype="multipart/form-data"
                  autocomplete="off"
                  @submit="approval_post"
                >
                  <div class="innar">
                    <div class="form_outwrap">
                      <div class="wrap wid_100per">
                        <p class="ttl_03">
                          <!-- top popup -->

                          宛先
                          <ErrorMessage class="each_msg" name="content" />
                        </p>
                        <div class="in_wrap">
                          <div class="select_wrap">
                            <div
                              class="aprroval_message"
                              v-if="message_type == 'news'"
                            >
                              内部統括部 部長執行役員に申請が行われます。
                            </div>
                            <div
                              class="aprroval_message"
                              v-else-if="
                                message_type == 'photolog' ||
                                message_type == 'column'
                              "
                            >
                              部門の役職者に申請が行われます。
                            </div>
                            <div v-else>
                              <select
                                v-model="approval_post_data.target_ids"
                                class=""
                              >
                                <option
                                  v-for="userlist in this.groupUsers.slice()"
                                  v-bind:value="userlist.id"
                                  :key="userlist.id"
                                >
                                  {{ userlist.last_name
                                  }}{{ userlist.first_name }}
                                  {{
                                    userlist.role
                                      ? " (" + userlist.role + ")"
                                      : ""
                                  }}
                                </option>
                              </select>
                            </div>
                            <div class="t_c">
                              <button
                                type="submit"
                                class="btn btn_submit btn_submit-blue"
                              >
                                申請
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
                <div
                  v-if="
                    messagecolumn['status_list'] == '承認' &&
                    showmessage.from_id == this.$User.id &&
                    showmessage.status != '公開'
                  "
                  class="tbl_list_command"
                >
                  <div class="commandlist t_c">
                    <div class="submit-btn">
                      <!--  top-edit -->
                      <router-link
                        v-if="
                          showmessage.status != '承認済' &&
                          showmessage.status != '申請中'
                        "
                        class="edit"
                        :to="{
                          name: 'Control messageedit',
                          params: { type: message_type, id: message_id },
                        }"
                        >編集
                      </router-link>
                    </div>
                    <div class="submit-btn">
                      <!-- top-approvel -->

                      {{ showmessage.status }}
                      <a
                        v-if="
                          showmessage.status != '承認済' &&
                          showmessage.status != '申請中'
                        "
                        class="delete"
                        @click="approvalmodal()"
                        data-tip="'承認申請'"
                      >
                        {{
                          approvelText(showmessage.status, showmessage.from_id)
                        }}
                      </a>
                      <!-- <a
                        v-if="showmessage.status == '申請中'"
                        class="delete"
                        @click="approvalmodal()"
                        data-tip="承認"
                        >承認</a
                      > -->
                    </div>
                  </div>
                </div>
              </div>

              <Form
                style="display: none"
                id="aprroval_ng_modal"
                v-if="showmessage.from_id != this.$User.id"
                enctype="multipart/form-data"
                autocomplete="off"
                @submit="approval_ng_message_post"
              >
                <div class="innar">
                  <div class="form_outwrap">
                    <div class="wrap wid_100per">
                      <p class="ttl_03">
                        理由 <ErrorMessage class="each_msg" name="content" />
                      </p>
                      <div class="in_wrap">
                        <Field
                          name="content"
                          type="text"
                          class="form-control"
                          v-model="approval_ng.content"
                          :rules="isRequired"
                        />
                      </div>
                    </div>
                    <div class="t_c m_b20">
                      <div class="btn_large">
                        <button type="submit" class="btn btn-primary">
                          送信
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>

              <div
                v-if="
                  messagecolumn['status_list'] == '承認' &&
                  showmessage.from_id != this.$User.id
                "
                class="tbl_list_command t_c"
              >
                <div class="submit-btn">
                  <!-- <a
                    v-if="showmessage.status == '承認済'"
                    class="edit active"
                    data-tip="承認"
                    >承認</a
                  >
                  <a
                    v-else-if="showmessage.status == '却下'"
                    class="edit not_active"
                    data-tip="承認"
                    >承認</a
                  >
                  <a
                    v-else
                    class="edit"
                    @click="approval_ok_message(showmessage.id)"
                    data-tip="承認"
                    >承認</a
                  > -->

                  <a
                    v-if="showmessage.status != '承認済'"
                    class="edit"
                    @click="approval_ok_message(showmessage.id)"
                    data-tip="承認"
                    >承認</a
                  >
                </div>
                <div class="submit-btn">
                  <!-- <a
                    v-if="showmessage.status == '却下'"
                    class="delete active"
                    data-tip="却下"
                    >却下</a
                  >
                  <a
                    v-else-if="showmessage.status == '承認済'"
                    class="delete not_active"
                    data-tip="却下"
                    >却下</a
                  >
                  <a
                    v-else
                    class="delete"
                    @click="approval_ng_message(showmessage.id)"
                    data-tip="却下"
                    >却下
                  </a> -->

                  <a
                    v-if="showmessage.status != '承認済'"
                    class="delete"
                    @click="approval_ng_message(showmessage.id)"
                    data-tip="却下"
                    >却下
                  </a>
                </div>
              </div>

              <div
                v-if="
                  showmessage.type == 'challenge' || showmessage.type == 'sdgs'
                "
                style="min-height: 300px"
              >
                <div class="season_wrap">
                  <h3>目標内容 1</h3>
                  <div v-html="showmessage.content"></div>
                </div>
                <div class="season_wrap">
                  <h3>目標内容２</h3>
                  <div v-html="showmessage.content_1"></div>
                </div>
                <div class="season_wrap">
                  <h3>目標内容３</h3>
                  <div v-html="showmessage.content_2"></div>
                </div>
              </div>

              <div
                v-if="
                  showmessage.type == 'column' ||
                  showmessage.type == 'photolog' ||
                  showmessage.type == 'idea' ||
                  showmessage.type == 'daily' ||
                  showmessage.type == 'thanks' ||
                  showmessage.type == 'news'
                "
                style="min-height: 300px"
              >
                <div class="season_wrap">
                  <!-- <h3>目標内容</h3> -->
                  <div v-html="showmessage.content"></div>
                </div>
              </div>

              <table>
                <tbody>
                  <tr v-if="messagecolumn['sdgs_type']">
                    <th>{{ messagecolumn["sdgs_type"] }}</th>
                    <td>{{ showmessage.sdgs_type }}</td>
                  </tr>
                  <tr
                    v-if="
                      messagecolumn['group'] &&
                      message_type !== 'sdgs' &&
                      message_type !== 'column' &&
                      message_type !== 'photolog' &&
                      message_type !== 'idea' &&
                      message_type !== 'challenge'
                    "
                  >
                    <th>{{ messagecolumn["group"] }}</th>
                    <td>
                      {{
                        showmessage.from_user
                          ? showmessage.from_user.group
                            ? showmessage.from_user.group.name
                            : ""
                          : ""
                      }}
                    </td>
                  </tr>

                  <!-- // MFUX-1308 サンクスタイプを投稿者の下に表示する -->
                  <tr
                    v-if="
                      messagecolumn['thanks_type'] && message_type !== 'thanks'
                    "
                  >
                    <th>{{ messagecolumn["thanks_type"] }}</th>
                    <td>
                      <span
                        v-for="thanks_type in this.showmessage.thanks_type"
                        :key="thanks_type.id"
                        >{{ thanks_type.value }}</span
                      >
                    </td>
                  </tr>
                  <tr
                    v-if="messagecolumn['public'] && message_type !== 'thanks'"
                  >
                    <th>{{ messagecolumn["public"] }}</th>
                    <td>{{ showmessage.public }}</td>
                  </tr>
                  <tr v-if="messagecolumn['published_at']">
                    <th>{{ messagecolumn["published_at"] }}</th>
                    <td>
                      <time
                        class="en"
                        :datetime="format_published(showmessage.published_at)"
                        >{{ format_published(showmessage.published_at) }}
                      </time>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      messagecolumn['tags'] &&
                      message_type !== 'sdgs' &&
                      message_type !== 'column' &&
                      message_type !== 'photolog' &&
                      message_type !== 'idea' &&
                      message_type !== 'challenge'
                    "
                  >
                    <th>{{ messagecolumn["tags"] }}</th>
                    <td>
                      <span v-for="tag in showmessage.tags" :key="tag.id">{{
                        tag.name
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-if="messagecolumn['photolog']">
                <img id="img_tag_id" />
              </div>

              <!-- <div v-if="files.length > 0">
								<h3>関係ファイルダウンロード</h3>
								<ul class="doc_list">
									<li class="doc_wrap" v-for="file in files" :key="file.id">
										<div class="doc_left">
											<p>{{ file.display_name }}</p>
										</div>
										<div class="doc_right">
											<div class="btn_norm">
												<a v-on:click="dlfile(file.storage_file_name)" class="en" target="_blank" :download="file.updaload_name">DL</a>
											</div>
										</div>
									</li>
								</ul>
							</div> -->

              <div
                v-if="
                  messagecolumn['attached_file'] && showmessage.attached_file
                "
              >
                <h3>{{ messagecolumn["attached_file"] }}</h3>
                <ul class="doc_list">
                  <li
                    class="doc_wrap"
                    v-for="file in showmessage.attached_file"
                    :key="file.id"
                  >
                    <div class="doc_left">
                      <p>{{ file.display_name }}</p>
                    </div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a
                          v-on:click="dlfile(file.storage_file_name)"
                          class="en"
                          target="_blank"
                          :download="file.updaload_name"
                          >DL</a
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </article>
            <div v-if="message_type == 'daily'">
              <div class="reply_area" v-if="reply_messages.data != 0">
                <div
                  class="reply_wrap"
                  v-for="(message, index) in reply_messages"
                  :key="message.id"
                  :id="'reply' + (index + 1)"
                >
                  <p class="res_head">
                    <span class="res_num en">{{ index + 1 }}</span
                    ><span class="en">.</span>
                    <span class="res_name" v-if="message.from_user"
                      >{{ message.from_user.last_name }}
                      {{ message.from_user.first_name }}</span
                    >
                    <span class="res_day_time">
                      <img
                        src="@/assets/front_component/images/assets/board01.png"
                        alt="時間"
                      />
                      <time
                        class="en"
                        :datetime="formatDate_time(message.published_at)"
                        >{{ formatDate(message.published_at) }}</time
                      >
                    </span>
                  </p>
                  <p v-if="message.reply_com_id" class="p_t10">
                    <a :href="'#reply' + message.reply_com_id"
                      >>> {{ message.reply_com_id }}</a
                    >
                  </p>
                  <div class="post_area">
                    <p v-html="htmlText(message.content)"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit_wrap">
              <!-- style="display:none" -->
              <Form
                id="aprroval_modal_under"
                class="click_popup dsn2"
                :style="display"
                v-if="
                  messagecolumn['status_list'] == '承認' &&
                  showmessage.from_id == this.$User.id &&
                  showmessage.status != '公開'
                "
                enctype="multipart/form-data"
                autocomplete="off"
                @submit="approval_post"
              >
                <div class="innar">
                  <div class="form_outwrap">
                    <div class="wrap wid_100per">
                      <!-- below popup -->
                      <p class="ttl_03">
                        宛先<ErrorMessage class="each_msg" name="content" />
                      </p>
                      <div class="in_wrap">
                        <div class="select_wrap">
                          <div
                            class="aprroval_message"
                            v-if="message_type == 'news'"
                          >
                            内部統括部 部長執行役員に申請が行われます。
                          </div>
                          <div
                            class="aprroval_message"
                            v-else-if="
                              message_type == 'photolog' ||
                              message_type == 'column'
                            "
                          >
                            部門の役職者に申請が行われます。
                          </div>
                          <div v-else>
                            <select
                              v-model="approval_post_data.target_ids"
                              class=""
                            >
                              <option
                                v-for="userlist in this.groupUsers.slice()"
                                v-bind:value="userlist.id"
                                :key="userlist.id"
                              >
                                {{ userlist.last_name
                                }}{{ userlist.first_name }}
                                {{
                                  userlist.role
                                    ? " (" + userlist.role + ")"
                                    : ""
                                }}
                              </option>
                            </select>
                          </div>
                          <div class="t_c">
                            <button
                              type="submit"
                              class="btn btn_submit btn_submit-blue"
                            >
                              申請
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
              <div
                v-if="
                  messagecolumn['status_list'] == '承認' &&
                  showmessage.from_id == this.$User.id &&
                  showmessage.status != '公開'
                "
                class="tbl_list_command"
              >
                <div class="commandlist t_c">
                  <div class="submit-btn">
                    <!-- buttom edit -->
                    <router-link
                      v-if="
                        showmessage.status != '承認済' &&
                        showmessage.status != '申請中'
                      "
                      class="edit"
                      :to="{
                        name: 'Control messageedit',
                        params: { type: message_type, id: message_id },
                      }"
                      >編集</router-link
                    >
                  </div>
                  <div class="submit-btn">
                    <!-- buttom approvel -->

                    <a
                      v-if="
                        showmessage.status != '承認済' &&
                        showmessage.status != '申請中'
                      "
                      class="delete"
                      @click="approvalmodal_under()"
                      data-tip="承認申請"
                    >
                      {{
                        approvelText(showmessage.status, showmessage.from_id)
                      }}</a
                    >
                    <!-- 下書き -->
                    <!-- <a 
                      v-if="showmessage.status == '申請中'"
                      class="delete"
                      @click="approvalmodal_under()"
                      data-tip="承認申請"
                      >承認</a
                    > -->
                  </div>
                </div>
              </div>
            </div>

            <Form
              style="display: none"
              id="aprroval_ng_modal"
              v-if="showmessage.from_id != this.$User.id"
              enctype="multipart/form-data"
              autocomplete="off"
              @submit="approval_ng_message_post"
            >
              <div class="innar">
                <div class="form_outwrap">
                  <div class="wrap wid_100per">
                    <p class="ttl_03">
                      理由 <ErrorMessage class="each_msg" name="content" />
                    </p>
                    <div class="in_wrap">
                      <Field
                        name="content"
                        type="text"
                        class="form-control"
                        v-model="approval_ng.content"
                        :rules="isRequired"
                      />
                    </div>
                  </div>
                  <div class="t_c">
                    <div class="btn_large">
                      <button type="submit" class="btn btn-primary">
                        送信
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <div
              v-if="
                messagecolumn['status_list'] == '承認' &&
                showmessage.from_id != this.$User.id
              "
              class="tbl_list_command t_c"
            >
              <div class="submit-btn">
                <!-- <a
                  v-if="showmessage.status == '承認済'"
                  class="edit active"
                  data-tip="承認"
                  >承認</a
                >
                <a
                  v-else-if="showmessage.status == '却下'"
                  class="edit not_active"
                  data-tip="承認"
                  >承認</a
                >
                <a
                  v-else
                  class="edit"
                  @click="approval_ok_message(showmessage.id)"
                  data-tip="承認"
                  >承認</a
                > -->
                <a
                  v-if="showmessage.status != '承認済'"
                  class="edit"
                  @click="approval_ok_message(showmessage.id)"
                  data-tip="承認"
                  >承認</a
                >
              </div>
              <div class="submit-btn">
                <!-- <a
                  v-if="showmessage.status == '却下'"
                  class="delete active"
                  data-tip="却下"
                  >却下</a
                >
                <a
                  v-else-if="showmessage.status == '承認済'"
                  class="delete not_active"
                  data-tip="却下"
                  >却下</a
                >
                <a
                  v-else
                  class="delete"
                  @click="approval_ng_message(showmessage.id)"
                  data-tip="却下"
                  >却下</a
                > -->

                <a
                  v-if="showmessage.status != '承認済'"
                  class="delete"
                  @click="approval_ng_message(showmessage.id)"
                  data-tip="却下"
                  >却下</a
                >
              </div>
            </div>
            <div class="t_c m_t100 m_b50">
              <div class="btn_wide blue">
                <a style="cursor: pointer" @click="gotoBack()">一覧にもどる</a>
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/bard01.png"
              alt="青鳥"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/bard02.png"
              alt="緑鳥"
            />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <!-- <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
            >お知らせ</router-link
          >
        </li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >アイディアカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import $ from "jquery";
import message from "@/apis/Message";
import user from "@/apis/User";
import moment from "moment";

//import $ from "jquery";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import NewsUtility from "@/apis/News";
import dayjs from "dayjs";
import { Form, Field, ErrorMessage } from "vee-validate";
import Groups from "../../../../apis/Groups";
import striptags from "striptags";

export default {
  name: "message",
  data() {
    return {
      userlists: "",
      target_user_id: "",
      showmessage: {
        content_1: "",
        content_2: "",
        option: "",
      },
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      messagetype: {},
      files: {},
      approval_ng: {},
      approval_post_data: {},
      reply_messages: {},
      profileImg: false,
      groupUsers: [],
      actionUserLabel: "投稿者",
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(from);
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  computed: {
    display() {
      if (
        (this.message_type == "photolog" || this.message_type == "column") &&
        this.showmessage.status == "下書き"
      ) {
        return "display:block";
      } else {
        return "display:none";
      }
    },
  },

  watch: {
    showmessage(val) {
      console.log(val);
    },
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = this.$route.params.type;
    this.message_id = this.$route.params.id;
    this.messagecolumn = MessageColumn[this.message_type];
    this.messagetype = MessageType[this.message_type];
    this.getUserLists();
    this.ShowMessage(this.message_id);
    this.getFile(this.message_id);
    if (this.message_type == "daily") {
      this.replyList(this.message_id);
    }
    this.profileImgApi();

    if(this.$User.group){

      //親のグループIDを取得
      var target_group_id_oya_daily = this.$User.group ?this.$User.group.id:null;
  
      if (
        this.$User.group.parent_id &&
        (this.message_type == "idea" ||
          this.message_type == "sdgs" ||
          this.message_type == "challenge")
      ) {
        target_group_id_oya_daily = this.$User.group.parent_id;
      } else {
        target_group_id_oya_daily = this.$User.group.id;
      }
  
      Groups.getGroupUsersByGroupId(
        target_group_id_oya_daily,
        this.message_type
      ).then((response) => {
        this.groupUsers = response.data.data;
      });
    }

  },
  mounted() {
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      // $(window).on('load',function(){
      // 	$(".media").addClass("selected");
      // 	alert('アラーートだよ');
      // });
    });
  },
  methods: {
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    gotoBack() {
      if (window.history.state.back == "/approval") {
        this.$router.push({
          name: "Front approval",
          query: {
            row_count: this.$route.query.row_count
              ? this.$route.query.row_count
              : 25,
          },
        });
      } else {
        this.$router.push({
          name: "Control message",
          params: { type: this.message_type },
          query: {
            row_count: this.$route.query.row_count
              ? this.$route.query.row_count
              : 25,
          },
        });
      }
    },
    getHistoryUrl() {
      if (window.history.state.back == "/approval") {
        return "承認";
      } else {
        return this.messagetype.title_jp;
      }
    },
    getUserLists() {
      if (this.messagecolumn["status_list"] == "投稿") {
        user
          .list()
          .then((response) => {
            if (response != null) {
              this.userlists = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push({ name: "Front Error Catch" });
          })
          .finally(() => {
            this.loader = false;
          });
      } else if (this.messagecolumn["status_list"] == "承認") {
        var target_user_data = [];

        if (this.$User.group) {
          user
            .get_group(this.$User.group.id)
            .then((response) => {
              if (response != null) {
                if (response.data.data.group_users.length > 0) {
                  response.data.data.group_users.forEach((user_data) => {
                    if (user_data.role != null) {
                      user
                        .show(user_data.user_id)
                        .then((response) => {
                          if (response != null) {
                            target_user_data.push(response.data.data);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                        .finally(() => {
                          this.loader = false;
                        });
                    }
                  });
                  this.userlists = target_user_data;
                }
              }
            })
            .catch((error) => {
              console.log(error);
              // this.$router.push({ name: 'Front Error Catch' });
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
    isRequired(value) {
      if (value) {
        return true;
      }
      return "必須です";
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formattime: (dateStr) =>
      dayjs(dateStr).add(9, "h").format("YYYY-MM-DD hh:mm:ss"),
    format_published: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    approvalmodal() {
      $("#aprroval_modal").show();
      this.approval_post_data.target_ids = this.showmessage.target_ids[0];
    },
    approvalmodal_under() {
      $("#aprroval_modal_under").show();
      this.approval_post_data.target_ids = this.showmessage.target_ids[0];
    },
    approval_post() {
      this.showmessage.status = "申請中";

      this.showmessage.published_at = moment(
        this.showmessage.published_at
      ).format("YYYY-MM-DD HH:mm:ss");

      if (
        this.message_type != "news" &&
        this.message_type != "photolog" &&
        this.message_type != "column"
      ) {
        this.showmessage.target_ids[0] = this.approval_post_data.target_ids;
      }

      var post_tag = [];

      if (this.showmessage.tags) {
        this.showmessage.tags.forEach((tag) => {
          post_tag.push(tag.id);
        });
        this.showmessage.tags = post_tag;
      }

      if (this.message_type == "sdgs" || this.message_type == "challenge") {
        this.showmessage.content = JSON.stringify({
          content: this.showmessage.content,
          content_1: this.showmessage.content_1,
          content_2: this.showmessage.content_2,
          option: this.showmessage.option,
          month: this.showmessage.month,
        });
      }

      this.showmessage.target_type = "users";

      this.showmessage.target_ids = this.showmessage.target_ids.filter(
        (item) => {
          return item != this.$User.id;
        }
      );

      message
        .update(this.showmessage, this.message_id)
        .then((response) => {
          if (response != null) {
            this.$router.push({
              name: "Control message",
              params: { type: this.message_type },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    approval_ok_message(messageid) {
      this.showmessage.status = "承認済";
      this.showmessage.published_at = this.formattime(
        this.showmessage.published_at
      );
      var post_tag = [];

      if (this.showmessage.tags) {
        this.showmessage.tags.forEach((tag) => {
          post_tag.push(tag.id);
        });
        this.showmessage.tags = post_tag;
      }
      message
        .update(this.showmessage, messageid)
        .then((response) => {
          if (response != null) {
            this.$router.push({ name: "Front approval" });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    approval_ng_message(messageid) {
      $("#aprroval_ng_modal").show();
      this.approval_ng.id = messageid;
    },
    approval_ng_message_post() {
      message
        .show(this.approval_ng.id)
        .then((response) => {
          if (response != null) {
            this.showmessage = response.data.data;
            this.showmessage.status = "却下";
            this.showmessage.published_at = this.formattime(
              this.showmessage.published_at
            );
            message
              .update(this.showmessage, this.approval_ng.id)
              .then((response) => {
                if (response != null) {
                  var ng_form = {
                    title: "却下メッセージ",
                    content: this.approval_ng.content,
                    content_type: "Plane",
                    parent_id: this.approval_ng.id,
                    type: "approval_message",
                    status: "公開",
                  };
                  message
                    .register(ng_form)
                    .then((response) => {
                      if (response != null) {
                        console.log(response);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {
                      this.loader = false;
                    });
                  this.$router.push({ name: "Front approval" });
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.loader = false;
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    ShowMessage(id) {
      message
        .show(id)
        .then((response) => {
          if (response != null) {
            this.showmessage = response.data.data;
            this.showmessage.from_user = "";
            if (
              this.showmessage.from_id != this.$User.id &&
              this.showmessage.target_ids.includes(this.$User.id) != true
            ) {
              this.$router.push({ name: "Front 404" });
            }

            if (
              this.message_type == "sdgs" ||
              this.message_type == "challenge"
            ) {
              if (this.IsJsonString(response.data.data.content)) {
                let contentSplit = JSON.parse(response.data.data.content);

                this.showmessage.content = striptags(contentSplit.content);

                this.showmessage.content_1 = striptags(contentSplit.content_1);

                this.showmessage.content_2 = striptags(contentSplit.content_2);

                this.showmessage.option = contentSplit.option;
              } else {
                this.showmessage.content = striptags(
                  response.data.data.content
                );
                this.showmessage.option = striptags(response.data.data.content);
              }
            }

            //Jquery
            // youtubeがタグの構成上表示出来ないので以下でタグを変換している
            $(function () {
              $("figure.media").each(function () {
                var $y_url = $(this)
                  .children()
                  .attr("url")
                  .replace("https://youtu.be/", "");
                $y_url = $(this)
                  .children()
                  .attr("url")
                  .replace("https://www.youtube.com/watch?v=", "");
                $(this)
                  .children()
                  .replaceWith(
                    "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
                  );
                console.log($y_url);
                $(this)
                  .children(".post_youtube")
                  .attr("src", "https://www.youtube.com/embed/" + $y_url);
              });
            });
            this.getMessageMeta(id);
            this.getMessageEyecatch(id);
            this.getMessageAttachedFile(id);

            if (this.showmessage.from_id != null) {
              this.getFromUser(this.showmessage.from_id);
            }

            // for aprovel user in column and photolog screen
            if (
              this.message_type == "column" ||
              this.message_type == "photolog"
            ) {
              this.showmessage.from_user = "";
              this.showmessage.act_users.forEach((user) => {
                if (user.act_type == "承認済") {
                  this.actionUserLabel = "承認者";
                  this.getFromUser(user.user_id);
                }
              });
            }

            if (this.showmessage.target_ids != null) {
              this.getTargetUser(this.showmessage.target_ids);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    getFromUser(user_id) {
      user
        .show(user_id)
        .then((response) => {
          if (response != null) {
            this.showmessage.from_user = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    getTargetUser(user_ids) {
      this.showmessage.target_user = [];
      user_ids.forEach((user_id) => {
        user
          .show(user_id)
          .then((response) => {
            if (response != null) {
              this.showmessage.target_user.push(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });
      });
    },
    getMessageMeta(message_id) {
      if (this.messagecolumn["sdgs_type"]) {
        message
          .showmetakey(message_id, "key", "sdgs_type")
          .then((response) => {
            if (response != null) {
              this.showmessage.sdgs_type = response.data.data[0].value;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["group"]) {
        message
          .showmetakey(message_id, "key", "group")
          .then((response) => {
            if (response != null) {
              this.showmessage.group = response.data.data[0].value;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["public"]) {
        message
          .showmetakey(message_id, "key", "public")
          .then((response) => {
            if (response != null) {
              this.showmessage.public = response.data.data[0].value;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["thanks_type"]) {
        message
          .showmetakey(message_id, "key", "thanks_type")
          .then((response) => {
            if (response != null) {
              this.showmessage.thanks_type = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },

    getMessageEyecatch(message_id) {
      if (this.messagecolumn["photolog"]) {
        message
          .showfile(message_id, "key", "photolog")
          .then((response) => {
            if (response.data.data[0]) {
              this.showmessage.photolog = response.data.data[0];
              let fileApiPass =
                "/api/v1/file/" + this.showmessage.photolog.storage_file_name;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then((response) => {
                  let blob = new Blob([response.data], {
                    type: response.headers["content-type"],
                  });
                  let img = document.getElementById("img_tag_id");
                  let url = window.URL || window.webkitURL;
                  img.src = url.createObjectURL(blob);
                });
            } else {
              this.getMessageEyecatchTime(message_id);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },

    getMessageEyecatchTime(message_id) {
      var timer_id;
      var i = 0;

      timer_id = setInterval(
        function () {
          this.getMessageEyecatch(message_id);
          i++;
          if (i == 5) {
            clearInterval(timer_id);
          }
        }.bind(this),
        2000
      );
    },

    getMessageAttachedFile(message_id) {
      if (this.messagecolumn["attached_file"]) {
        message
          .showfile(message_id, "key", "attached_file")
          .then((response) => {
            if (response.data.data.length != 0) {
              this.showmessage.attached_file = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
    getFile(message_id) {
      message
        .showfile(message_id, "key", "attached_file")
        .then(
          (response) => {
            if (response != "") {
              this.files = response.data.data;
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch(() => {
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    dlfile(path) {
      this.windowReference = window.open();
      let fileApiPass = "/api/v1/file/" + path;
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          this.windowReference.location = window.URL.createObjectURL(blob);
        });
    },
    replyList(id) {
      this.loader = true;
      message
        .listChild(id, "daily_res")
        .then(
          (response) => {
            if (response != null) {
              this.reply_messages = response.data.data;

              this.reply_messages.forEach((reply_message) => {
                user
                  .show(reply_message.from_id) //投稿者の取得
                  .then((response) => {
                    if (response != null) {
                      reply_message.from_user = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    htmlText(msg) {
      return msg.replace(/\r?\n/g, "<br>");
    },
    showYear(year) {
      if (typeof year == "number") {
        year = year.toString();
      }

      let setYear = "";
      switch (year) {
        case "2022":
          setYear = "49期 (2022)";
          break;
        case "2023":
          setYear = "50期 (2023)";
          break;
        case "2024":
          setYear = "51期 (2024)";
          break;
        case "2025":
          setYear = "52期 (2025)";
          break;
        case "2026":
          setYear = "53期 (2026)";
          break;
        default:
          setYear = year;
          break;
      }

      return setYear;
    },
    approvelText(status, fromId) {
      return "承認申請";
    },
    seletecedUser(userId) {
      return this.approval_post_data.target_ids.includes(userId);
    },
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  },
  updated() {
    console.log("アップデート");
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
